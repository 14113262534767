
@keyframes opacity{
    100%{
        opacity:  0;
        transition: all ease;
         }
    90%{
      opacity:  0.2;
         }
    80%{
      opacity:  0.3;
        
    }
    70%{
      opacity:  0.4;
       
    }
    60%{
      opacity:  0.5;
  }
    50%{
      opacity:  0.6;
        
    }
    40%{
      opacity:  0.7;  
  }
  30%{ opacity: 0.8;}
  20%{ opacity: 0.9;}
    0%{
      opacity:  1;  
  }
  }